import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { MapToComponents } from 'react-map-to-components';
import SEO from '../components/SEO';
import QuotationMarks from '../assets/images/quotationMarks.inline.svg';
import ThreeColumns from '../components/sections/ThreeColumns';
import TwoColumns from '../components/sections/TwoColumns';
import SingleColumn from '../components/sections/SingleColumn';
import PortableText from '../components/PortableText';
import { mq } from '../styles/breakpoints';
import BackArrow from '../assets/images/BackArrow.inline.svg';
import TwoColumnsQuery from '../fragments/TwoColumns';
import ThreeColumnsQuery from '../fragments/ThreeColumns';
import SingleColumnQuery from '../fragments/SingleColumn';

const TestimonyStyles = styled.div`
  padding-top: var(--margin);

  h1.section-title {
    margin-bottom: var(--margin);
    text-align: center;
  }
  .testimony-container {
    svg {
      display: block;
      max-width: 18.75rem;
    }
  }
  .testimony-text {
    margin-top: var(--margin);
    padding-bottom: var(--margin);

    ${mq[2]} {
      border-bottom: 1px solid var(--blue-dark);
    }
  }

  .excerpt {
    font-style: italic;
    margin-bottom: 2rem;
  }

  .quotation-marks {
    height: 1.25rem;

    path {
      fill: var(--blue-dark);
    }
  }

  .qm-open {
    margin-right: auto;
  }
  .qm-closed {
    margin: 1.2em 0 0 auto;
    transform: rotate(180deg);
  }

  .author {
    font-size: 0.9em;
    text-align: right;
  }
`;

export default function SingleTestimonyPage({ data: { testimony }, location }) {
  return (
    <>
      <SEO
        title={testimony.titleSeo || testimony.place}
        description={testimony.descriptionSeo}
        location={location}
        // image={testimony.image?.asset.fluid.src}
      />
      <TestimonyStyles>
        <div className="container testimony-container" id="content">
          <Link to="/temoignages" className="back">
            <BackArrow className="back__arrow" />
            <span className="back__label">Tous les témoignages</span>
          </Link>
          <div className="testimony-text">
            <h1 className="section-title">{testimony.title}</h1>
            {testimony.testimonyExcerpt && (
              <PortableText
                blocks={testimony.testimonyExcerpt._rawText}
                id="excerpt"
              />
            )}
            <QuotationMarks className="quotation-marks qm-open" />
            {testimony.testimonyText && (
              <PortableText blocks={testimony.testimonyText._rawText} />
            )}
            <QuotationMarks className="quotation-marks qm-closed" />
            <p className="author">{testimony.author}</p>
          </div>
          <div className="testimony-content">
            <MapToComponents
              getKey={(section) => section.id || section._key}
              getType={(section) => section._type}
              list={testimony.content}
              map={{
                threeColumns: ThreeColumns,
                twoColumns: TwoColumns,
                singleColumn: SingleColumn,
              }}
              mapDataToProps={{
                threeColumns: ({ data }) => ({
                  title: data.title,
                  bgTitle: data.bgTitle?.value,
                  bgSection: data.bgSection?.value,
                  showTitle: data.showTitle,
                  cards: data.cards,
                  titleOptions: data.titleOptions,
                }),
                twoColumns: ({ data }) => ({
                  bgColor: data.bgColor?.value,
                  alignContent: data.alignContent,
                  title: data.title,
                  showTitle: data.showTitle,
                  cards: data.cards,
                  colsProportions: data.colsProportions,
                  ctaButton: data.ctaButton,
                  titleOptions: data.titleOptions,
                }),
                singleColumn: ({ data }) => ({
                  background: data.background?.value,
                  description: data.columnDescription?._rawText,
                  alignCenter: data.alignCenter,
                  ctaButton: data.ctaButton,
                  title: data.title,
                  showTitle: data.showTitle,
                  titleOptions: data.titleOptions,
                }),
                steps: ({ data }) => ({
                  title: data.title,
                  steps: data.steps,
                }),
              }}
            />
          </div>
        </div>
      </TestimonyStyles>
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    testimony: sanityTestimony(id: { eq: $id }) {
      testimonyExcerpt {
        _rawText(resolveReferences: { maxDepth: 10 })
      }
      testimonyText {
        _rawText(resolveReferences: { maxDepth: 10 })
      }
      id
      title
      titleSeo
      descriptionSeo
      author
      place
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData(width: 879)
        }
      }
      content {
        ...SingleColumnQuery
        ...TwoColumnsQuery
        ...ThreeColumnsQuery
      }
    }
  }
`;
